<template>
  <div class="daily_transaction">
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <a>&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;单日成交详情</a>
    </div>
    <div class="list">
      <div class="gameSelect">
        <div id="gameSelect">
          <el-select :popper-append-to-body="false" v-model="value" placeholder="请选择游戏"  @change="selectChange">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="{value:item.value,label:item.label}" ></el-option>
          </el-select>
        </div>
      </div>
      <div class="content">
        <div class="daily_transaction_content_item" v-for="(item,index) in dailyTransaction" :key="index" >
          <div class="deal_img">
            <img class="productImg" :src="item.image" alt="">
            <img class="imgBackground" src="@/assets/image/DailyTransaction/detailsImgBackgroud.png" alt="">
          </div>
          <div class="details_info">
            <div class="details_info_top">
              <span>{{item.game_name}}</span>
              {{item.name}}
            </div>
            <div class="details_info_mid">
              <span v-for="(categoryItem,index) in item.category" :key="index">
                {{categoryItem.name.toString().slice(1,categoryItem.name.toString().length)}}:
                {{categoryItem.value}}
              </span>
            </div>
            <div class="details_info_bot">
              <div class="details_info_bot_left">
                商品价格：
                <span id="productPrice">¥{{ item.price }}</span>
              </div>
              <div class="details_info_bot_right">
                <span class="hot_pic"><img src="@/assets/image/DailyTransaction/topGamesIco.png" alt="">{{item.click}}</span>
                <span v-if="item.pay_time > item.pay_time_xuni" class="release_time">成交时间：{{ item.pay_time|moment }}</span>
                <span v-else class="release_time">成交时间：{{ item.pay_time_xuni|moment }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pages">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="PageSize"
          layout="prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {apiDesktopIndex, apiGameList,apiProductTotal,apiPDailyDetails} from "@/request/API";

export default {
  name: 'dailyTransaction',
  props: {
  },
  components: {

  },
  data () {
    return {
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:0,
      // 默认每页显示的条数（可修改）
      PageSize:10,

      game_id:this.$route.query.game_id,
      dateValue:this.$route.query.time * 1000,

      options: [],
      value:{},
      dailyTransaction:[]
    }
  },
  methods: {
    // 行数据点击事件
    rowClick(item){
      this.$router.push({
        name:'GameDetails',
        query:{
          game_id:item.game_id,
          id:item.id
        }
      });
    },
    //游戏下拉框监听事件
    selectChange(event){
      this.currentPage = 1
      this.value = event
      this.game_name = event.label
      this.game_id = event.value
      this.getPDailyDetails()
    },
    //分页监听事件
    handleCurrentChange(val) {
      this.currentPage = val
      this.getPDailyDetails()
    },
    /**
     * 获取所有游戏 下拉框
     */
    getSelectData(){
      apiGameList({
      }).then(res=>{
        res.data.forEach((item,index)=>{
          let options = new Object()
          options.value = item.game_id
          options.label = item.game_name
          if(item.game_id == this.$route.query.game_id){
            this.value.value = item.game_id
            this.value.label = item.game_name
          }
          this.options.push(options)
        })
      })
    },
    getPDailyDetails(){
      apiPDailyDetails({
        game_id:this.game_id,
        date:this.dateValue.toString().slice(0,10),
        page:this.currentPage
      }).then(res=>{
        this.currentPage = res.data.page
        this.totalCount = res.data.total
        this.dailyTransaction = res.data.list
      })

    }
  },
  mounted(){
    this.getSelectData()
    this.getPDailyDetails()
  },
  watch: {

  },
  computed: {

  }
}
</script>

<style>
.el-date-table td.today span{
  color: #FCAD13
}
.el-date-table td.end-date span, .el-date-table td.start-date span{
  background-color: #FCAD13
}
.el-date-table td.end-date span:hover, .el-date-table td.start-date span:hover{
  color: #FFF !important;
}
.available *:hover{
  color: #FCAD13 ;
}
.el-date-table td.current:not(.disabled) span {
  color: #FFF;
  background-color: #FCAD13;
}
</style>

<style lang='less' scoped>
.daily_transaction{
  width: 1200px;
  .title{
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    a{
      font-size: 12px;
    }
    a:first-of-type{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }
    a:last-of-type{
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #434ED6;
      cursor: pointer;
    }
  }
  .list{
    width: 1200px;
    background: #FFFFFF;
    //padding: 20px 30px 35px 40px;
    .gameSelect{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 30px 0 40px;
      height: 80px;
      #gameSelect{
        /deep/.el-select{
          margin: 0 40px 0 0;
          width: 198px;
          height: 38px;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #C0C4CC;
          line-height: 14px;
        }
        /deep/.el-input.is-focus .el-input__inner{
          border-color:#FCAD13 !important;
        }
        /deep/.el-select .el-input__inner:focus{
          border-color:#FCAD13 !important;
        }
        /deep/.el-select-dropdown__item.hover{
          background-color:#FFFBF3;
        }
        /deep/.el-select-dropdown__item.selected{
          color:#FCAD13;
        }
      }
      #datePicker{
        /deep/.el-input__inner{
          height: 40px;
          border-radius: 4px;
          border: 1px solid #DCDFE6;
        }
      }
    }
    .content{
      display: flex;
      flex-direction: column;
      .daily_transaction_content_item{
        height: 150px;
        padding: 30px 30px 30px 40px;
        display: flex;
        flex-direction: row;
        //cursor: pointer;
        border-bottom: 1px solid #E2E2E2;
        .deal_img{
          width: 150px;
          height: 150px;
          margin: 0 20px 0 0;
          position: relative;

          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
          .productImg{
          }
          .imgBackground{
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .details_info{
          width: 960px;
          //margin-right: 48px;
          .details_info_top{
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #8C8C8C;
            line-height: 22px;
            margin-bottom: 10px;
            height: 66px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-align: justify; /*实现两端对齐*/
            text-justify: newspaper; /*通过增加或减少字或字母之间的空格对齐文本*/
            word-break: break-all; /*允许在单词内换行*/
            span{
              background: #DADADA;
              border-radius: 15px;
              font-size: 15px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              padding: 0 17px 0 18px;
              margin-right: 5px;
            }
          }
          .details_info_mid{
            margin-bottom: 10px;
            span{
              font-size: 16px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              margin-right: 30px;
              color: #C2C2C2;
              line-height: 22px;
            }
          }
          .details_info_bot{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .details_info_bot_left{
              font-size: 16px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #C2C2C2;
              line-height: 22px;
              #productPrice{
                font-size: 28px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #AAAAAA;
                line-height: 40px;
              }
            }
            .details_info_bot_right{
              font-size: 16px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #C2C2C2;
              line-height: 22px;

              .release_time{
                margin: 0 0 0 30px;
              }
              .hot_pic{
                img{
                  width: 18px;
                  height: 18px;
                  object-fit: cover;
                }

              }
            }
          }
        }
      }
      .daily_transaction_content_item:last-of-type{
        border-bottom: none;
      }
    }
    .pages{
      padding: 29px 0 35px 0;
      display: flex;
      justify-content: center;
    }
  }
}
</style>

